














import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    Avatar: () => import("@/components/general/avatar.vue"),
  },
})
export default class WorkspaceCard extends Vue {
  @Prop() workspace!: Workspace;

  get workspaceRoute(): string {
    const domain = this.workspace.url;
    const parts = process.env.VUE_APP_WEB_URL.split("://");
    return `${parts[0]}://${domain}.${parts[1]}/overview`;
  }
}
